import { useCallback, useEffect, useMemo, useState } from "react";
import { useClaimCheck } from "hooks/useClaimCheck";
import { usePageHeader } from "hooks/usePageHeader";
import { useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { CollectionGatedContentPageStripeSection } from "./CollectionGatedContentPageStripeSection";
import { useParams } from "hooks/useParams";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import { usePutCollection } from "hooks/requests/usePutCollection";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
import { isValidGatedContentPassword } from "helpers/gatedContent";
const cx = classnames.bind(styles);

export const CollectionGatedContentPage = () => {
    const dispatch = useDispatch();
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const { t } = useTranslation();
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const hasEmailGating = useClaimCheck("gatedcontent.email");
    const hasPasswordGating = useClaimCheck("gatedcontent.password");
    const [stripeSectionHandleSubmit, setStripeSectionHandleSubmit] = useState<
        () => void
    >(() => {});
    const [childHasChanges, setChildHasChanges] = useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const headerSubtitle = useMemo(() => {
        if (!hasEmailGating && !hasPasswordGating) {
            return t("page-titles:subheaders:gated-content");
        }
        if (hasEmailGating && !hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-email-only");
        }
        if (!hasEmailGating && hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-password-only");
        }
        if (hasEmailGating && hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-full");
        }
    }, [hasEmailGating, hasPasswordGating, t]);

    const {
        collection,
        onChange,
        update,
        loading,
        hasChanges: putCollectionHasChanges,
        getVideoCollection
    } = usePutCollection(videoPlayerId);

    useEffect(() => {
        setIsInitialLoad(loading);
    }, [loading]);

    usePageHeader({
        title: t("collection-page:collections-settings-gated-content"),
        subTitle: headerSubtitle,
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("collection-page:collections-settings-gated-content")
        ],
        autoSave: isInitialLoad ? false : loading
    });

    const hasChanges: boolean = useMemo(() => {
        return putCollectionHasChanges || childHasChanges;
    }, [putCollectionHasChanges, childHasChanges]);

    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    const passwordIsValid = useCallback(() => {
        const { Password, IsPasswordGatingEnabled } = collection?.VideoPlayer;
        const isInvalid =
            IsPasswordGatingEnabled && !isValidGatedContentPassword(Password);

        setHasPasswordValidationError(isInvalid);
        return !isInvalid;
    }, [collection]);

    const [collectionHasEntitlements, setCollectionHasEntitlements] =
        useState<boolean>(
            collection?.VideoPlayerEntitlements.ProductEntitlements.length > 0
        );

    useEffect(() => {
        setCollectionHasEntitlements(
            collection?.VideoPlayerEntitlements.ProductEntitlements.length > 0
        );
    }, [collection?.VideoPlayerEntitlements.ProductEntitlements.length]);

    const submit = useCallback(async () => {
        if (!passwordIsValid()) return;

        const promises = [];

        promises.push(update());

        if (stripeSectionHandleSubmit) {
            promises.push(stripeSectionHandleSubmit());
        }

        try {
            setIsSubmitting(true);
            await Promise.all(promises);
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t("players:messages:gated-options-update-success")
                })
            );
        } catch (error) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message:
                        error.message ?? t("players:messages:update-failed")
                })
            );
        } finally {
            setIsSubmitting(false);
        }
    }, [update, stripeSectionHandleSubmit, passwordIsValid, dispatch, t]);

    const [selectedStripeValue, setSelectedStripeValue] = useState<
        string | null
    >();

    useEffect(() => {
        setCollectionHasEntitlements(
            childHasChanges
                ? !!selectedStripeValue
                : collection?.VideoPlayerEntitlements?.ProductEntitlements
                      ?.length > 0
        );

        // If the user has entitlements and the stripe value is selected, then we should disable email gating
        if (childHasChanges && !!selectedStripeValue) {
            onChange("IsEmailGatingEnabled", false);
        }
    }, [
        childHasChanges,
        selectedStripeValue,
        collection?.VideoPlayerEntitlements?.ProductEntitlements?.length,
        onChange
    ]);

    useBeforeUnload(hasChanges, null, true);

    return (
        <>
            {hasGatedContentAccess && (
                <>
                    <div className={cx("gate-options-form")}>
                        {hasPasswordGating && (
                            <PasswordGatingToggle
                                isEnabled={
                                    collection?.VideoPlayer
                                        ?.IsPasswordGatingEnabled
                                }
                                setIsEnabled={(value) =>
                                    onChange("IsPasswordGatingEnabled", value)
                                }
                                password={collection?.VideoPlayer?.Password}
                                setPassword={(value) =>
                                    onChange("Password", value)
                                }
                                hasEmailOrPurchaseGating={
                                    collectionHasEntitlements ||
                                    collection?.VideoPlayer
                                        ?.IsEmailGatingEnabled
                                }
                                hasValidationError={hasPasswordValidationError}
                            />
                        )}
                        {hasEmailGating && (
                            <Toggle
                                on={
                                    collectionHasEntitlements ||
                                    collection?.VideoPlayer
                                        ?.IsEmailGatingEnabled
                                }
                                label={t("collection-page:toggle-email")}
                                disabled={collectionHasEntitlements || loading}
                                reverseLayout
                                onToggle={() =>
                                    onChange(
                                        "IsEmailGatingEnabled",
                                        !collection?.VideoPlayer
                                            ?.IsEmailGatingEnabled
                                    )
                                }
                            />
                        )}

                        <CollectionGatedContentPageStripeSection
                            videoPlayerId={videoPlayerId}
                            onReset={getVideoCollection}
                            setHandleSubmit={setStripeSectionHandleSubmit}
                            setHasChanges={setChildHasChanges}
                            setSelectedValue={setSelectedStripeValue}
                            disabled={isSubmitting}
                        />

                        <Button
                            disabled={loading || !hasChanges}
                            onClick={() => {
                                submit();
                            }}
                        >
                            {t("buttons:save-changes")}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
