import { AppDispatch } from "store/store";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import {
    Broadcast,
    BroadcastResponse,
    BroadcastStatus,
    BroadcastWebLink,
    Category,
    CloudflareVideo,
    CreatorProduct,
    CreatorProductEntitlement,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductEntitlementsRequest,
    PlaylistItemResponse,
    VideoPlayer,
    VideoPlayerPlaylistBroadcast,
    VideoPlayerPlaylistBroadcastUpdateRequest,
    WebLinkRequest,
    WebLinkRequestType
} from "@switcherstudio/switcher-api-client";
import { BroadcastThumbnail } from "components/thumbnails/BroadcastThumbnail";
import { isInFuture } from "helpers/time";
import { BroadcastDetailsForm } from "components/forms/BroadcastDetailsForm";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { useDispatch } from "react-redux";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { VideoPlaybackModal } from "components/modal/VideoPlaybackModal";
import { exists } from "helpers/booleans";
import { useInterval } from "hooks/useInterval";
import { VideoUploadStatus } from "store/uploads/types";
import { useVideoUpload } from "hooks/useVideoUpload";
import { DisabledVariant } from "components/entity-details/BroadcastDetails";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { mapBroadcastResponseToBroadcast } from "helpers/mappers/mapBroadcastResponseToBroadcast";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Spinner } from "components/spinners/Spinner";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { useCreatorProductEntitlement } from "hooks/useCreatorProductEntitlement";
import PassEmptyState from "components/empty-state/PassEmptyState";
import { Link } from "react-router-dom";
import { useClaimCheck } from "hooks/useClaimCheck";
import { PricingSelectModal } from "components/modal/PricingSelectModal";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
import { isValidGatedContentPassword } from "helpers/gatedContent";
import { useUserStripeData } from "hooks/useUserStripeData";

export interface VideoDetailsPageProps {
    broadcast: Broadcast | BroadcastResponse;
    /** the playable video from Cloudflare - used in the preview */
    video: CloudflareVideo;
    /** used in the video library to manipulate to which collections a video belongs */
    players?: VideoPlayer[];
    /** has the entitlements specific to the broadcast within a collection */
    playlistBroadcast?: PlaylistItemResponse;
    /** refetch that broadcast! */
    refetchBroadcast?: () => void;
    /** for conditional rendering of location-specific components */
    location: "video-library" | "collection";
    /** used to edit the entitlements */
    collectionId?: string;
    /** is the broadcast data loading while returning from the API? */
    broadcastLoading?: boolean;
}
export const VideoDetailsPage = ({
    broadcast,
    video,
    players,
    playlistBroadcast,
    refetchBroadcast,
    location,
    collectionId,
    broadcastLoading
}: VideoDetailsPageProps) => {
    const isBroadcastResponse = (
        broadcast: any
    ): broadcast is BroadcastResponse => {
        return (broadcast as BroadcastResponse)?.Details !== undefined;
    };

    const dispatch = useDispatch<AppDispatch>();
    const { getUploadStatus } = useVideoUpload();
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const [localBroadcast, setLocalBroadcast] = useState<Broadcast>();
    const [localEntitlement, setLocalEntitlement] =
        useState<CreatorProductEntitlementsRequest>();
    const [pricingSelectModalOpen, setPricingSelectModalOpen] =
        useState<boolean>(false);
    const [isEmailGatingEnabled, setIsEmailGatingEnabled] = useState<boolean>(
        playlistBroadcast?.Details?.IsEmailGatingEnabled
    );
    const productHasEntitlement = useMemo<boolean>(
        () => exists(playlistBroadcast?.Entitlements?.ProductEntitlements),
        [playlistBroadcast?.Entitlements?.ProductEntitlements]
    );
    const [links, setLinks] = useState<BroadcastWebLink[]>([]);
    const [initialLinks, setInitialLinks] = useState<BroadcastWebLink[]>([]);

    /** only set existing entitlement if it exists */
    useEffect(() => {
        productHasEntitlement &&
            setExistingEntitlement(
                playlistBroadcast?.Entitlements?.ProductEntitlements?.[0]
            );
    }, [
        productHasEntitlement,
        playlistBroadcast?.Entitlements?.ProductEntitlements
    ]);

    const [existingEntitlement, setExistingEntitlement] =
        useState<CreatorProductEntitlement>(
            playlistBroadcast?.Entitlements?.ProductEntitlements?.[0]
        );

    const [passwordLocal, setPasswordLocal] = useState<string>(
        playlistBroadcast?.Details?.Password
    );

    const [isPasswordGatingEnabled, setIsPasswordGatingEnabled] =
        useState<boolean>(playlistBroadcast?.Details?.IsPasswordGatingEnabled);

    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    const [broadcastStatus, setBroadcastStatus] = useState<BroadcastStatus>(
        localBroadcast?.BroadcastStatus
    );

    const [broadcastStartsAt, setBroadcastStartsAt] = useState<string>(
        localBroadcast?.StartsAt
    );

    const [categories, setCategories] = useState<Category[]>(
        localBroadcast?.Categories ?? []
    );

    const [description, setDescription] = useState<string>(
        localBroadcast?.Description
    );

    const [showInCatalog, setShowInCatalog] = useState<boolean>(
        localBroadcast?.ShowInCatalog
    );
    const [thumbnailFile, setThumbnailFile] = useState<File>();
    /** this is the thumbnail the user has uploaded */
    const [customThumbnailImageURL, setCustomThumbnailImageURL] =
        useState<string>();

    // this is used in the library video details page only
    const existingPlayers: string[] = useMemo<string[]>(
        () => players?.map((p) => p.Id),
        [players]
    );

    const [addToPlayers, setAddToPlayers] = useState<string[]>(existingPlayers);

    useEffect(() => {
        if (playlistBroadcast?.Details?.IsEmailGatingEnabled !== undefined) {
            setIsEmailGatingEnabled(
                playlistBroadcast.Details.IsEmailGatingEnabled
            );
        }
    }, [playlistBroadcast]);

    const { addCreatorProductEntitlement, deleteCreatorProductEntitlement } =
        useCreatorProductEntitlement(collectionId, {
            suppressNotifications: true,
            onAdd: () => setExistingEntitlement(null),
            onDelete: () => setExistingEntitlement(null)
        });

    const {
        accountData,
        productData,
        loading: stripeDataLoading
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: true
    });
    const stripeConnected =
        accountData?.gatedContentStatus === GatedContentStatus.READY;
    const userHasPasses = productData?.oneTimeProducts?.length > 0;

    const handlePassAssignment = useCallback(
        async (creatorProduct: CreatorProduct) => {
            if (creatorProduct) {
                // create new product entitlement
                setLocalEntitlement({
                    ProductEntitlements: [
                        {
                            ProductId: creatorProduct.Id,
                            VideoPlayerPlaylistBroadcastId:
                                playlistBroadcast?.Details?.Id,
                            Discriminator:
                                CreatorProductEntitlementsBindingModelDiscriminator._2
                        }
                    ]
                });
            }
            setPricingSelectModalOpen(false);
        },
        [playlistBroadcast]
    );

    const selectPass = useCallback(
        async (selection) => {
            // do nothing if it's the same as what was selected before
            if (
                selection?.Id === existingEntitlement?.Product?.Id ||
                !selection
            ) {
                setLocalEntitlement(selection);
            }

            handlePassAssignment(selection);
        },
        [existingEntitlement, handlePassAssignment]
    );

    const { dispatchApiRequest: getBroadcastWebLinks } = useSwitcherClient(
        (client) => client.webLink_GetBroadcastWebLinks
    );
    const { dispatchApiRequest: postWebLinks } = useSwitcherClient(
        (client) => client.webLink_Post,
        {
            requestImmediately: false
        }
    );

    useEffect(() => {
        if (localEntitlement) {
            setIsEmailGatingEnabled(true);
        } else {
            setIsEmailGatingEnabled(
                playlistBroadcast?.Details?.IsEmailGatingEnabled
            );
        }
    }, [localEntitlement, playlistBroadcast?.Details?.IsEmailGatingEnabled]);

    useEffect(() => {
        if (playlistBroadcast?.Details?.IsPasswordGatingEnabled !== undefined) {
            setIsPasswordGatingEnabled(
                playlistBroadcast.Details.IsPasswordGatingEnabled
            );
            setPasswordLocal(playlistBroadcast.Details.Password);
        }
    }, [playlistBroadcast]);

    const validatePassword = useCallback(() => {
        if (
            isPasswordGatingEnabled &&
            !isValidGatedContentPassword(passwordLocal)
        ) {
            setHasPasswordValidationError(true);
            return false;
        } else {
            setHasPasswordValidationError(false);
            return true;
        }
    }, [isPasswordGatingEnabled, passwordLocal]);

    const fetchLinks = useCallback(async () => {
        try {
            const response = await getBroadcastWebLinks([[localBroadcast?.Id]]);
            setInitialLinks(response);
            setLinks(response);
        } catch (e) {
            rollbar.error(e);
        }
    }, [localBroadcast?.Id, getBroadcastWebLinks]);

    // TODO: Reconcile the success and error states for the client requests on this page
    const { dispatchApiRequest: updatePlaylistBroadcast } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylistBroadcast_UpdateVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    const handleEmailToggleChange = useCallback(() => {
        setIsEmailGatingEnabled((prev) => !prev);
    }, []);

    /** determine what kind of broadcast was sent in from the parent component
     * and set the local broadcast state to always be Broadcast
     * (for ease of data manipulation) */
    useEffect(() => {
        if (!isBroadcastResponse(broadcast)) {
            setLocalBroadcast(broadcast);
        } else if (isBroadcastResponse(broadcast)) {
            const mappedBroadcast = mapBroadcastResponseToBroadcast(broadcast);
            setLocalBroadcast(mappedBroadcast);
        }
    }, [broadcast]);

    const showGatingOptions = useMemo(() => {
        return (
            location === "collection" &&
            playlistBroadcast &&
            hasGatedContentAccess
        );
    }, [location, playlistBroadcast, hasGatedContentAccess]);

    /** initialize state once the data exists */
    useEffect(() => {
        if (!!localBroadcast) {
            setTitle(localBroadcast?.Title);
            setDescription(localBroadcast?.Description);
            setCustomThumbnailImageURL(
                localBroadcast?.ThumbnailAsset?.SignedUrl
            );
            setCategories(localBroadcast?.Categories ?? []);
            setBroadcastStatus(localBroadcast?.BroadcastStatus);
            setBroadcastStartsAt(localBroadcast?.StartsAt);
            setShowInCatalog(localBroadcast?.ShowInCatalog);
            setLinks(localBroadcast?.BroadcastWebLinks ?? []);
            setAddToPlayers(existingPlayers);
            setLocalEntitlement(
                productHasEntitlement
                    ? {
                          ProductEntitlements: [
                              {
                                  ProductId:
                                      playlistBroadcast?.Entitlements
                                          ?.ProductEntitlements?.[0].ProductId,
                                  VideoPlayerPlaylistBroadcastId:
                                      playlistBroadcast?.Details?.Id,
                                  Discriminator:
                                      CreatorProductEntitlementsBindingModelDiscriminator._2
                              }
                          ]
                      }
                    : undefined
            );
            fetchLinks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localBroadcast, existingPlayers]);

    /** this is the thumbnail automatically chosen on upload */
    const activeThumbnailImageURL = useMemo(
        () =>
            localBroadcast?.ThumbnailAsset?.SignedUrl ||
            (exists(video) ? video.thumbnail : ""),
        [localBroadcast, video]
    );

    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);
    const [timerEndTime, setTimerEndTime] = useState<Date>();
    const [timeRemaining, setTimeRemaining] = useState<number>(
        timerEndTime && timerEndTime?.getTime() < new Date().getTime()
            ? timerEndTime?.getTime() - new Date().getTime()
            : 0
    );
    const [title, setTitle] = useState<string>(localBroadcast?.Title);
    const [triggerThumbnailUpdate, setTriggerThumbnailUpdate] =
        useState<number>(0);

    const { handleUpdateThumbnail, handleDeleteThumbnail } = useThumbnailUpload(
        thumbnailFile,
        customThumbnailImageURL
    );

    const isLive = useMemo<boolean>(() => {
        return (
            (localBroadcast?.InputId !== null &&
                localBroadcast?.ActiveAt === null) ||
            localBroadcast?.EndedAt === null
        );
    }, [
        localBroadcast?.ActiveAt,
        localBroadcast?.EndedAt,
        localBroadcast?.InputId
    ]);

    // If the broadcast is scheduled to premiere and is in the future,
    // it is considered a scheduled upload.
    const isScheduledUpload = useMemo(() => {
        let isScheduled =
            exists(localBroadcast?.StartsAt) &&
            isInFuture(localBroadcast?.StartsAt) &&
            localBroadcast?.BroadcastStatus === BroadcastStatus._3;

        return isScheduled;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localBroadcast, triggerThumbnailUpdate]);

    // If the broadcast is scheduled to go live any time and has not started yet,
    // it is considered a scheduled live broadcast.
    const isScheduledLive = useMemo(() => {
        let isScheduled =
            exists(localBroadcast?.StartsAt) &&
            localBroadcast?.BroadcastStatus === BroadcastStatus._1;

        return isScheduled;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localBroadcast, triggerThumbnailUpdate]);

    // This is used to trigger a thumbnail refresh when the scheduled event timer reaches 0.
    const triggerThumbnailRefresh = useCallback(() => {
        setTriggerThumbnailUpdate((prev) => prev + 1);
    }, [setTriggerThumbnailUpdate]);

    // This will initialize the timer for the scheduled event when timerEndTime is set.
    useInterval(
        () => {
            calcTimeRemaining(timerEndTime);
        },
        timeRemaining > 0 ? 1000 : null
    );

    // Initial run of calcuation before interval begins.
    useEffect(() => {
        calcTimeRemaining(timerEndTime);
    }, [timerEndTime]);

    // Used to trigger refreshing some data (IE: thumbnails)
    // when the when the scheduled event timer reaches 0.
    useEffect(() => {
        if (timeRemaining > 0) return;

        triggerThumbnailRefresh();
    }, [timeRemaining, triggerThumbnailRefresh]);

    // This is used to calculate the time remaining for the scheduled event timer.
    const calcTimeRemaining = (timeEnd: Date) => {
        if (!timeEnd) return;
        const now = new Date().getTime();
        const distance = timeEnd.getTime() - now;

        setTimeRemaining(distance > 0 ? distance : 0);
    };

    useEffect(() => {
        const isScheduled = isScheduledUpload || isScheduledLive;
        if (isScheduled && isInFuture(localBroadcast?.StartsAt)) {
            setTimerEndTime(new Date(localBroadcast?.StartsAt));
        }
    }, [localBroadcast, isScheduledLive, isScheduledUpload]);

    const saveLinks = useCallback((links) => {
        setLinks(links);
    }, []);

    const { dispatchApiRequest: putBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_PutBroadcast,
        {
            requestImmediately: false
        }
    );

    const { dispatchApiRequest: addToPlayer } = useSwitcherClient(
        (client) => client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    const { dispatchApiRequest: removeFromPlayer } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_DeleteVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    /** handle playing the preview video */
    const handlePlayVideo = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.VideoPlaybackModal,
                type: Modals.VideoPlaybackModal,
                component: <VideoPlaybackModal src={video?.embedUrl} />
            })
        );
    }, [dispatch, video?.embedUrl]);

    const videoState = useMemo<VideoUploadStatus>(() => {
        const isInitialStatusProcessingOrQueued =
            video?.status?.state === VideoUploadStatus.Processing ||
            video?.status?.state === VideoUploadStatus.Queued;
        if (isInitialStatusProcessingOrQueued)
            return getUploadStatus({ broadcastId: localBroadcast?.Id });

        return VideoUploadStatus.Success;
    }, [video?.status?.state, getUploadStatus, localBroadcast?.Id]);

    const videoIsProcessing = useMemo<boolean>(
        () => videoState !== VideoUploadStatus.Success,
        [videoState]
    );

    const { dispatchApiRequest: getVideoPlayer, loading: videoPlayerLoading } =
        useSwitcherClient((client) => client.videoPlayers_GetVideoPlayer, {
            requestImmediately: false,
            hideLoading: true
        });

    /** the Big Mama, who updates all Broadcast values */
    const handleSubmit = useCallback(async () => {
        if (!validatePassword()) {
            return;
        }

        setIsSubmitting(true);
        const promises = [];

        const webLinkRequest: WebLinkRequest = {
            Id: localBroadcast?.Id,
            webLinks: links,
            Type: WebLinkRequestType.Broadcast
        };

        promises.push(postWebLinks([webLinkRequest]));

        try {
            let updatedBroadcast: Broadcast = {
                ...localBroadcast,
                Title: title,
                Description: description,
                Categories: categories,
                ShowInCatalog: showInCatalog,
                BroadcastStatus: broadcastStatus,
                StartsAt: broadcastStartsAt
                    ? new Date(broadcastStartsAt).toISOString()
                    : new Date().toISOString(),
                ...(broadcastStartsAt
                    ? { ActiveAt: new Date(broadcastStartsAt).toISOString() }
                    : { ActiveAt: new Date().toISOString() }),
                ...(broadcastStartsAt && video?.duration
                    ? {
                          EndedAt: new Date(
                              Math.round(
                                  (new Date(broadcastStartsAt).getTime() +
                                      video?.duration * 1000) /
                                      1000
                              ) * 1000
                          ).toISOString()
                      }
                    : video?.duration && {
                          EndedAt: new Date(
                              Math.round(
                                  (new Date().getTime() +
                                      video?.duration * 1000) /
                                      1000
                              ) * 1000
                          ).toISOString()
                      })
            };

            if (thumbnailFile) {
                const thumbnail = await handleUpdateThumbnail(localBroadcast);
                updatedBroadcast.ThumbnailAssetId = thumbnail.Id;
                updatedBroadcast.ThumbnailAsset = thumbnail;
            } else if (thumbnailRemoved) {
                handleDeleteThumbnail(localBroadcast?.ThumbnailAssetId);
                updatedBroadcast.ThumbnailAsset = undefined;
                updatedBroadcast.ThumbnailAssetId = undefined;
            }

            if (!!addToPlayers) {
                for (const player of addToPlayers) {
                    if (!existingPlayers.includes(player)) {
                        const playerResponse = await getVideoPlayer([player]);
                        const playlist =
                            playerResponse.VideoPlayer.VideoPlayerPlaylists[0];
                        promises.push(
                            addToPlayer([
                                playlist.Id,
                                {
                                    PlaylistBroadcast: {
                                        BroadcastId: localBroadcast?.Id,
                                        VideoPlayerPlaylistId: playlist?.Id
                                    }
                                }
                            ])
                        );
                    }
                }
            }

            if (!!existingPlayers) {
                for (const player of existingPlayers) {
                    if (!addToPlayers.includes(player)) {
                        const playerResponse = await getVideoPlayer([player]);
                        const playlist =
                            playerResponse.VideoPlayer.VideoPlayerPlaylists[0];
                        promises.push(
                            removeFromPlayer([
                                playlist.Id,
                                playlist.VideoPlayerPlaylistBroadcasts.find(
                                    (pb) =>
                                        pb.BroadcastId === localBroadcast?.Id
                                )
                            ])
                        );
                    }
                }
            }

            if (playlistBroadcast && location === "collection") {
                const updatedPlaylistBroadcast: VideoPlayerPlaylistBroadcast = {
                    VideoPlayerPlaylistId:
                        playlistBroadcast?.Details?.VideoPlayerPlaylistId,
                    BroadcastId: playlistBroadcast?.Details?.BroadcastId,
                    IsEmailGatingEnabled: isEmailGatingEnabled,
                    IsPasswordGatingEnabled: isPasswordGatingEnabled,
                    OrdinalRank: {
                        Ordinal: playlistBroadcast?.Details?.Ordinal,
                        Rank: playlistBroadcast?.Details?.Rank
                    },
                    Password: passwordLocal,
                    Id: playlistBroadcast?.Details?.Id,
                    CreatedAt: playlistBroadcast?.Details?.CreatedAt,
                    UpdatedAt: playlistBroadcast?.Details?.UpdatedAt
                };

                const updateRequest: VideoPlayerPlaylistBroadcastUpdateRequest =
                    {
                        PlaylistBroadcast: updatedPlaylistBroadcast
                    };

                promises.push(
                    updatePlaylistBroadcast([
                        updateRequest,
                        playlistBroadcast?.Details?.Id
                    ])
                );
            }

            promises.push(
                putBroadcast([
                    localBroadcast?.Id,
                    updatedBroadcast,
                    false,
                    false
                ])
            );

            if (localEntitlement) {
                await addCreatorProductEntitlement([localEntitlement]);
            } else if (!!existingEntitlement) {
                await deleteCreatorProductEntitlement([
                    existingEntitlement?.Id
                ]);
            }

            await Promise.all(promises);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t("playlist-page:edit-success")
                })
            );

            refetchBroadcast();
        } catch (e) {
            rollbar.error("Error editing broadcast", e);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("playlist-page:edit-error")
                })
            );
        } finally {
            setIsSubmitting(false);
        }
    }, [
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement,
        existingEntitlement,
        localEntitlement,
        validatePassword,
        localBroadcast,
        links,
        postWebLinks,
        title,
        description,
        categories,
        showInCatalog,
        broadcastStatus,
        broadcastStartsAt,
        video?.duration,
        thumbnailFile,
        thumbnailRemoved,
        addToPlayers,
        existingPlayers,
        playlistBroadcast,
        location,
        putBroadcast,
        dispatch,
        t,
        refetchBroadcast,
        handleUpdateThumbnail,
        handleDeleteThumbnail,
        getVideoPlayer,
        addToPlayer,
        removeFromPlayer,
        isEmailGatingEnabled,
        isPasswordGatingEnabled,
        passwordLocal,
        updatePlaylistBroadcast
    ]);

    /** check if any fields on the broadcast form have changed */
    const hasChanges = useMemo(() => {
        if (!localBroadcast) return false;

        const broadcastHasChanges =
            localBroadcast.Title !== title ||
            localBroadcast.Description !== description ||
            localBroadcast.ShowInCatalog !== showInCatalog ||
            localBroadcast.BroadcastStatus !== broadcastStatus ||
            localBroadcast.StartsAt !== broadcastStartsAt;

        const thumbnailHasChanges =
            (!!localBroadcast.ThumbnailAssetId && thumbnailRemoved) ||
            thumbnailFile !== undefined;

        const categoriesHaveChanges =
            JSON.stringify(localBroadcast.Categories) !==
            JSON.stringify(categories);

        const playersHaveChanges =
            JSON.stringify(existingPlayers) !== JSON.stringify(addToPlayers);

        const emailGatingHasChanges =
            hasEmailProtectedContentClaim &&
            location === "collection" &&
            isEmailGatingEnabled !==
                playlistBroadcast?.Details?.IsEmailGatingEnabled;

        const passwordGatingHasChanges =
            hasPasswordProtectedContentClaim &&
            location === "collection" &&
            (isPasswordGatingEnabled !==
                playlistBroadcast?.Details?.IsPasswordGatingEnabled ||
                passwordLocal !== playlistBroadcast?.Details?.Password);

        const hasWeblinkChanges =
            JSON.stringify(links) !== JSON.stringify(initialLinks);

        const passHasChanges =
            existingEntitlement?.ProductId !==
            localEntitlement?.ProductEntitlements?.[0]?.ProductId;

        return (
            broadcastHasChanges ||
            thumbnailHasChanges ||
            categoriesHaveChanges ||
            playersHaveChanges ||
            emailGatingHasChanges ||
            passwordGatingHasChanges ||
            hasWeblinkChanges ||
            passHasChanges
        );
    }, [
        localEntitlement,
        existingEntitlement,
        localBroadcast,
        title,
        description,
        showInCatalog,
        broadcastStatus,
        broadcastStartsAt,
        thumbnailRemoved,
        thumbnailFile,
        categories,
        existingPlayers,
        addToPlayers,
        hasEmailProtectedContentClaim,
        location,
        isEmailGatingEnabled,
        playlistBroadcast?.Details?.IsEmailGatingEnabled,
        playlistBroadcast?.Details?.IsPasswordGatingEnabled,
        playlistBroadcast?.Details?.Password,
        hasPasswordProtectedContentClaim,
        isPasswordGatingEnabled,
        passwordLocal,
        links,
        initialLinks
    ]);

    /** this needed to be set in state because we don't PUT
     * the new pass til we click the save button, but we need the name
     * to be correct once the user has selected a new pass in the modal */
    const passName = useMemo<string>(() => {
        if (localEntitlement?.ProductEntitlements?.[0] !== undefined) {
            return productData?.oneTimeProducts?.find(
                (otp) =>
                    otp.Id ===
                    localEntitlement?.ProductEntitlements?.[0]?.ProductId
            )?.Name;
        } else if (localEntitlement === undefined) {
            return t("video-player-settings:add-pricing");
        }
        return t("video-player-settings:add-pricing");
    }, [localEntitlement, productData?.oneTimeProducts, t]);

    /** tell the user they've got unsaved changes */
    useBeforeUnload(hasChanges, null, true);

    /** pagewide loading */
    const loading = useMemo(
        () => broadcastLoading || videoPlayerLoading || isSubmitting,
        [broadcastLoading, videoPlayerLoading, isSubmitting]
    );

    return !localBroadcast || loading ? (
        <div className={styles["loading"]}>
            <Spinner size={128} />
        </div>
    ) : (
        <div className={styles["broadcast-details-container"]}>
            <div className={styles["broadcast-details-form-column"]}>
                <BroadcastDetailsForm
                    broadcastId={localBroadcast?.Id}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    setThumbnailFile={setThumbnailFile}
                    thumbnailImageURL={customThumbnailImageURL}
                    setThumbnailImageURL={setCustomThumbnailImageURL}
                    onRemoveThumbnail={() => setThumbnailRemoved(true)}
                    selectedCategories={categories}
                    setSelectedCategories={setCategories}
                    broadcastStatus={broadcastStatus}
                    setBroadcastStatus={setBroadcastStatus}
                    startsAt={broadcastStartsAt}
                    setStartsAt={setBroadcastStartsAt}
                    links={links}
                    onLinkSave={saveLinks}
                    liveBroadcast={isLive}
                    showThumbnailUploader
                    showCategories
                    showInCatalog={showInCatalog}
                    setShowInCatalog={setShowInCatalog}
                    isUpload={false}
                    orientation={
                        video?.input?.width >= video?.input?.height
                            ? AspectRatio.horizontal
                            : AspectRatio.vertical
                    }
                    players={addToPlayers}
                    setAddToPlayers={setAddToPlayers}
                    location={"video-details"}
                />
                {showGatingOptions && (
                    <div className={styles["gating-options-container"]}>
                        <h6
                            className={
                                !stripeConnected ? styles["remove-mb"] : ""
                            }
                        >
                            {t("video-details-page:gating-options")}
                        </h6>
                        {hasPasswordProtectedContentClaim && (
                            <PasswordGatingToggle
                                isEnabled={isPasswordGatingEnabled}
                                setIsEnabled={setIsPasswordGatingEnabled}
                                password={passwordLocal}
                                setPassword={setPasswordLocal}
                                hasEmailOrPurchaseGating={isEmailGatingEnabled}
                                hasValidationError={hasPasswordValidationError}
                            />
                        )}
                        {hasEmailProtectedContentClaim && (
                            <Toggle
                                on={isEmailGatingEnabled}
                                label={t("collection-page:toggle-email")}
                                disabled={
                                    videoIsProcessing || !!localEntitlement
                                }
                                reverseLayout
                                onToggle={handleEmailToggleChange}
                            />
                        )}

                        {/* 3 potential states.*/}
                        {!stripeDataLoading ? (
                            !stripeConnected ? (
                                // 1. stripe not connected.
                                <PassEmptyState
                                    message={t(
                                        "players:gated-content:connect-stripe-account-description"
                                    )}
                                    linkText={t(
                                        "players:gated-content:connect-stripe-account-link-text"
                                    )}
                                    linkHref={accountData?.link?.href}
                                ></PassEmptyState>
                            ) : !userHasPasses ? (
                                // 2. stripe connected, but no passes created yet.
                                <div>
                                    <Trans
                                        i18nKey={t(
                                            "gated-content-page:video-create-pass"
                                        )}
                                        components={{
                                            link1: (
                                                <Link
                                                    to={"/gated-content#passes"}
                                                    title="Manage Gated Content Passes"
                                                    className={styles["link"]}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            ) : (
                                // 3. stripe connected and has created passes.
                                <Button
                                    disabled={
                                        accountData?.gatedContentStatus !==
                                        GatedContentStatus.READY
                                    }
                                    type="badge"
                                    isActive={
                                        productHasEntitlement ||
                                        !!localEntitlement
                                    }
                                    onClick={() => {
                                        setPricingSelectModalOpen(true);
                                    }}
                                >
                                    {passName}
                                </Button>
                            )
                        ) : (
                            <Spinner size={44} alignment="left" />
                        )}
                    </div>
                )}
            </div>
            <div className={styles["broadcast-thumbnail-column"]}>
                <div className={styles["broadcast-thumbnail-sticky-container"]}>
                    <div className={styles["save-button"]}>
                        <Button
                            disabled={!hasChanges}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t("buttons:save-changes")}
                        </Button>
                    </div>
                    <div className={styles["video-thumbnail"]}>
                        <BroadcastThumbnail
                            thumbnailImageURL={activeThumbnailImageURL}
                            allowVideoPlaybackOnThumbnailClick={
                                !videoIsProcessing
                            }
                            handlePlayVideo={handlePlayVideo}
                            isScheduledUpload={isScheduledUpload}
                            isScheduledLive={isScheduledLive}
                            scheduledSecondsRemaining={timeRemaining ?? 0}
                            disabled={videoIsProcessing}
                            disabledVariant={
                                videoIsProcessing
                                    ? DisabledVariant.Processing
                                    : DisabledVariant.Disabled
                            }
                        />
                    </div>
                </div>
            </div>

            <PricingSelectModal
                isOpen={pricingSelectModalOpen}
                setIsOpen={setPricingSelectModalOpen}
                buttonText={t("buttons:done")}
                handleSelect={(selection) => selectPass(selection)}
                products={productData?.oneTimeProducts}
                selected={localEntitlement?.ProductEntitlements?.[0]?.ProductId}
                discriminator={
                    CreatorProductEntitlementsBindingModelDiscriminator._2
                }
            />
        </div>
    );
};
